<template>
  <v-container fluid :class="{'px-0': $vuetify.breakpoint.xsOnly }" style="padding-bottom: 0px">
    <v-row align="center"
           justify="center"
    >
      <v-col cols="4" class="mb-1 mt-1" style="text-align: center">
        <img :style="{width: '100%'}" src="/web-02.png"/>
      </v-col>

      <v-col cols="4" offset="4" class="mb-1 mt-1" style="text-align: center">
        <v-btn @click="dialog=true"
               style="width: 8vh; height: 8vh; border-radius: 8vw"
        >MENU
        </v-btn>
      </v-col>
    </v-row>

    <v-img src="/Romantika_Christmas_49A0158_Colored.jpg" rel="preload"></v-img>


    <v-img src="/Romantika_Christmas_49A0007_Colored.jpg" rel="preload"></v-img>


    <v-img src="/Romantika_Christmas_49A9956_Colored.jpg" rel="preload"></v-img>

    <v-img src="/Romantika_Christmas_49A0020_Colored.jpg" rel="preload"></v-img>

    <v-img src="/Romantika_Christmas_49A0049_Colored.jpg" rel="preload"></v-img>
    <!--
    <v-row>
      <v-col offset="0" md="6" sm="6" lg="4" offset-lg="4">
        <v-btn block @click="dialog=true">MENU</v-btn>
      </v-col>
    </v-row>
   -->
    <v-dialog
        v-model="dialog"
        width="800px"
    >
      <v-card style="background-color: #ffada4">

        <v-card-text>
          <pdf v-for="i in numPages"
               :key="i"
               :src="src"
               :page="i"
               style="display: inline-block; width: 100%; margin: 5px">

          </pdf>
        </v-card-text>

      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import pdf from "vue-pdf"

var loadingTask = pdf.createLoadingTask('/meni-zlicke.pdf');

export default {
  name: 'HelloWorld',
  components: {pdf},
  data: () => ({
    src: loadingTask,
    numPages: undefined,
    dialog: false
  }),
  mounted() {
    this.src.promise.then(pdf => {
      this.numPages = pdf.numPages;
    });
  },
  computed: {
    logo_width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '30%'
        case 'sm':
          return '50%'
        case 'md':
          return '40%'
        case 'lg':
          return '33%'
        case 'xl':
          return '33%'
      }
      return '33%'
    }
  }
}
</script>
